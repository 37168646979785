<template>
  <div class="voice-dropdowns">
    <Dropdown
      :items="getFilteredLanguages(voicesAndLanguages, selectedLanguage)"
      selectType="language"
      label="choose language"
      @change="onlanguageChange($event)"
    />
    <Dropdown
      selectType="voice"
      :items="getVoices(selectedLanguage, getFilteredVoices, $t)"
      @change="onvoiceChange($event, getFilteredVoices)"
      ref="test"
    />
    <!-- <Button
      :text="$t('_buttons.apply')"
      buttonType="voice-tab-apply"
      @onClick="applyEffects(getFilteredVoices, tempSelectedLanguage)"
    /> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watchEffect } from 'vue'
import Dropdown from '@/components/UI/DropDown.vue'
// import Button from '@/components/UI/Button.vue'
import { useStore } from '@/store'
import { VoicesMutationTypes as VoicesMutationType } from '@/store/voices/mutations'
import { useI18n } from 'vue-i18n'
import { getVoices } from '@/utils/getVoices'

export default defineComponent({
  components: {
    Dropdown
    // Button
  },

  props: {
    voiceIndexvalue: {
      type: Number
    }
  },

  setup(props) {
    const store = useStore()
    const voiceChange = ref(0)
    const { t } = useI18n()
    // const sortOrder = [
    //   'en-US',
    //   'en-GB',
    //   'nl-NL',
    //   'de-DE',
    //   'fr-FR',
    //   'it-IT',
    //   'es-ES',
    //   'da-DK',
    //   'el-GR',
    //   'hu-HU',
    //   'fi-FI',
    //   'nb-NO',
    //   'sv-SE',
    //   'pt-PT',
    //   'pl-PL',
    //   'ja-JP'
    //   // 'th-TH'
    // ]

    const sortOrder = [
      'en-US',
      'en-GB',
      'nl-NL',
      'de-DE',
      'fr-FR',
      'it-IT',
      'es-ES',
      'da-DK',
      'el-GR',
      'hu-HU',
      'fi-FI',
      'nb-NO',
      'sv-SE',
      'pt-PT',
      'pl-PL',
      'ja-JP',
      'af-ZA',
      'ar-XA',
      'bg-BG',
      'bn-IN',
      'ca-ES',
      'cmn-CN',
      'cmn-TW',
      'cs-CZ',
      'en-AU',
      'en-IN',
      'es-US',
      'eu-ES',
      'fil-PH',
      'fr-CA',
      'gl-ES',
      'gu-IN',
      'he-IL',
      'hi-IN',
      'id-ID',
      'is-IS',
      'kn-IN',
      'ko-KR',
      'lt-LT',
      'lv-LV',
      'ml-IN',
      'mr-IN',
      'ms-MY',
      'nl-BE',
      'pa-IN',
      'pt-BR',
      'ro-RO',
      'ru-RU',
      'sk-SK',
      'sr-RS',
      'ta-IN',
      'te-IN',
      'th-TH',
      'tr-TR',
      'uk-UA',
      'vi-VN',
      'yue-HK'
    ]
    const voicesAndLanguages = computed(() => store.state.voices.voicesAndLanguages)
    const tempSelectedLanguage = computed(() => store.state.voices.tempSelectedLanguage)
    const selectedLanguage = computed(() => store.state.voices.selectedLanguage)
    const userData = computed(() => store.state.voices.userData)
    // const voiceType = computed(() => store.state.voices.voiceType)
    const selectedVoice = computed(() => store.state.voices.selectedVoice)
    const getFilteredVoices = computed(() => store.getters.filteredVoices)

    const getFilteredLanguages = (voicesAndLanguages, selectedLanguage) => {
      let languages = voicesAndLanguages
        .filter((voice) => sortOrder.find((code) => code === voice.languageCodes[0]))
        .sort((a, b) => sortOrder.indexOf(a.languageCodes[0]) - sortOrder.indexOf(b.languageCodes[0]))
        .map((voice) => {
          const langName = voice.languageCodes[0]
          return {
            value: voice.languageCodes[0],
            label: t(`_dropDownContent.${langName}`),
            voicename: voice.name,
            selected: selectedLanguage
          }
        })

      store.commit(VoicesMutationType.SET_LANGUAGE, languages)
      languages = languages
        .filter((v, i, a) => a.findIndex((v2) => v2.value === v.value) === i)
        .sort((a, b) => a.label.localeCompare(b.label))

      return languages
    }

    const onlanguageChange = async (e) => {
      voiceChange.value = 0
      e.target.nextSibling.selectedIndex = 0
      await store.commit(VoicesMutationType.SET_SELECTED_LANGUAGE, e.target.value)
      await store.commit(
        VoicesMutationType.SET_SELECTED_VOICE,
        getVoices(selectedLanguage.value, getFilteredVoices.value, t).filter(
          (voice) => voice.value == voiceChange.value
        )
      )

      const sc = selectedVoice.value.map((voice) => {
        return { voicename: voice.voiceCode }
      })
      'dataLayer' in window
        ? (window.dataLayer.push({
            event: 'vog_select_language',
            eventCategory: 'voice_over_generator',
            eventAction: 'apply',
            eventLabel: `language_${selectedLanguage.value}`
          }),
          window.dataLayer.push({
            event: 'vog_select_voice',
            eventCategory: 'voice_over_generator',
            eventAction: 'apply',
            eventLabel: `voice_${sc[0].voicename}`
          }))
        : null
    }

    const onvoiceChange = async (e, getFilteredVoices) => {
      voiceChange.value = e.target.value
      await store.commit(
        VoicesMutationType.SET_SELECTED_VOICE,
        getVoices(selectedLanguage.value, getFilteredVoices, t).filter((voice) => voice.value == voiceChange.value)
      )
      const sc = selectedVoice.value.map((voice) => {
        return { voicename: voice.voiceCode }
      })
      'dataLayer' in window
        ? window.dataLayer.push({
            event: 'vog_select_voice',
            eventCategory: 'voice_over_generator',
            eventAction: 'apply',
            eventLabel: `voice_${sc[0].voicename}`
          })
        : null
    }

    watchEffect(() => (voiceChange.value = props.voiceIndexvalue || 0))

    return {
      voicesAndLanguages,
      getFilteredLanguages,
      onlanguageChange,
      onvoiceChange,
      selectedLanguage,
      selectedVoice,
      getFilteredVoices,
      // applyEffects,
      tempSelectedLanguage,
      userData,
      getVoices
    }
  }
})
</script>
<style lang="sass" scoped>
.voice-dropdowns
  display: flex
  padding: 24px 16px
  gap: 16px
  @media screen and (max-width: $mdBreakpoint)
    gap: 10px
  @media screen and (max-width: $xsBreakpoint)
    flex-direction: column
    gap: 16px
</style>
