<template>
  <div class="tab-content" v-show="title == selectedTitle">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, provide } from "vue";
export default defineComponent({
  props: ["title"],
  setup() {
    const selectedTitle = inject("selectedTitle");
    provide("selectedTitle", selectedTitle);
    return {
      selectedTitle,
    };
  },
});
</script>
<style lang="sass" scoped>
.tab-content
  width: 100%
  background: $colorWhite
</style>
