import { ref } from "vue"

export default function () {
    const sliderDataOptions = ref({
        adsorb: true,
        width: '91.7%',
        height: 0,
        hideLabels: true,
        dotSize: [41, 29],
        enableCross: false,
        tooltip: 'always',
        railStyle: {
            'backgroundColor':'#0A0A38',
            'borderRadius': '0px',
            'height': '1px'
        },
        dotStyle: {
            'border': '0px',
            // 'box-shadow': '1px 1px 10px rgba(44, 0, 171, 0.2)',
            'border-radius': '4px',
            'background': '#C1E4E6'
        },
        processStyle: {
            'backgroundColor': '#0A0A38',
            'height': '1px',
            'top': '0px'
        },
        tooltipStyle: {
            'backgroundColor': 'transparent',
            'borderColor': 'transparent',
            'color': '#08082B !important',
            'font-family': 'Faro-Variable',
            'transform': 'translate(0%, 150%)',
            'font-size': '15px'
            // 'position': 'relative',
            // 'top': '35px'
        }
    })
    return { sliderDataOptions }
}