<template>
  <div class="silencetab-dropdown">
    <Dropdown :items="values" selectType="silenceSelect" @change="onSilenceChange($event)" />
    <div class="applybtn-tooltip">
      <div class="applybutton" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
        <Button
          :text="$t('_buttons.apply')"
          buttonType="silence-tab-apply"
          :is-disabled="silenceValue != '0ms' ? disableSilenceApply : true"
          @click="applyEffects"
        />
      </div>
      <CustomTooltip
        :style="{ opacity: showTooltip ? 1 : 0, visibility: showTooltip ? 'visible' : 'hidden' }"
        :toolTipText="$t('_toolTip.effectDisabledToolTipText')"
        :disableTooltip="disableSilenceApply || silenceValue == '0ms'"
        toolTipType="applybtn"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, watchEffect } from 'vue'
import Dropdown from '@/components/UI/DropDown.vue'
import Button from './UI/Button.vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { VoicesMutationTypes as VoicesMutationType } from '@/store/voices/mutations'
import { addTagsToSelection } from '@/utils/addTagsToSelection'
import router from '@/router'
import CustomTooltip from '@/components/UI/CustomToolTip.vue'

export default defineComponent({
  components: {
    Dropdown,
    Button,
    CustomTooltip
  },
  props: {
    userId: {
      type: String
    },
    disableSilenceApply: {
      type: Boolean
    }
  },
  setup() {
    const store = useStore()
    const { t } = useI18n()
    const routeQuery = router.currentRoute.value.query

    const disableApplyButton = ref(true)
    const values = ref([
      { value: '0ms', label: '0ms', selected: '250ms' },
      { value: '100ms', label: '100ms', selected: '250ms' },
      { value: '250ms', label: '250ms', selected: '250ms' },
      { value: '500ms', label: '500ms', selected: '250ms' },
      { value: '1s', label: '1s', selected: '250ms' },
      { value: '2s', label: '2s', selected: '250ms' },
      { value: '3s', label: '3s', selected: '250ms' },
      { value: '4s', label: '4s', selected: '250ms' },
      { value: '5s', label: '5s', selected: '250ms' }
    ])
    const silenceValue = ref('250ms')
    const showTooltip = ref(false)
    const silenceStore = computed(() => store.state.voices.voiceSilenceValue)

    const onSilenceChange = (e) => {
      silenceValue.value = e.target.value
    }
    const applyEffects = () => {
      const selection = document.getSelection()

      if (selection.isCollapsed == true && silenceValue.value != '0ms') {
        store.commit(VoicesMutationType.SET_VOICE_SILENCE_VALUE, silenceValue.value)
        values.value.map((value) => (value.selected = silenceValue.value))
      }
      const tagClass = 'silence'
      addTagsToSelection(selection, silenceValue.value, '0ms', silenceValue.value, tagClass, t, 'CRUD')
    }

    watchEffect(() => {
      silenceStore.value
      if (silenceStore.value == '') {
        values.value.map((value) => (value.selected = '250ms'))
      }
    })

    return {
      values,
      onSilenceChange,
      applyEffects,
      disableApplyButton,
      routeQuery,
      silenceValue,
      showTooltip
    }
  }
})
</script>
<style lang="sass" scoped>
.silencetab-dropdown
  display: flex
  padding: 24px 16px
  gap: 16px
  @media screen and (max-width: $mdBreakpoint)
    gap: 10px
  @media screen and (max-width: $xsBreakpoint)
    flex-direction: column
    gap: 16px

.applybtn-tooltip
  position: relative
</style>
