<template>
  <div class="container" @click="textareaFocus">
    <span class="lang--voice">
      <span class="lang" :class="{ 'cream-teal': selectedTitle == $t('_tabNames.voice') }"
        >{{ $t('_labels.language') }} : {{ languageName }}</span
      >
      <span class="lang" :class="{ 'cream-teal': selectedTitle == $t('_tabNames.voice') }"
        >{{ $t('_labels.voice') }} : {{ voiceName.voiceIndex + '. ' + voiceName.voiceLabel }}</span
      >
    </span>
    <span class="placeholder" v-if="placeholderText">{{ placeholder }}</span>
    <span
      ref="textArea"
      class="tarea"
      contenteditable="true"
      label="text"
      :data-placeholder="placeholder"
      :onchange="
        () => {
          textArea.parentElement.normalize()
        }
      "
      @keyup="($event) => $emit('onKeyUp', $event)"
      @keypress="($event) => $emit('onKeyPress', $event)"
      @paste.prevent="
        ($event) => {
          handlePaste($event, maxLength), $emit('onKeyUp', $event)
        }
      "
      @copy="($event) => $emit('onCutOrCopy', $event)"
      @cut="($event) => $emit('onCutOrCopy', $event)"
      >&#8203;
    </span>
  </div>
</template>

<script lang="ts">
// import { setEndOfContenteditable } from "@/utils/setEndOfContenteditable";
import { handlePaste } from '@/utils/handlePaste'
import { defineComponent, onMounted, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 2500
    },
    selectedTitle: {
      type: String
    },
    languageName: {
      type: String
    },
    voiceName: {
      type: Object
    },
    placeholder: {
      type: String
    },
    userId: {
      type: String
    }
  },

  setup(props) {
    const textArea = ref(null)
    const { t } = useI18n()
    const regex = /\s+/gi
    const placeholderText = ref(true)

    const textareaFocus = () => {
      textArea.value.focus()
    }

    const changeTagsClass = (selectedTitle) => {
      const selectedClass = selectedTitle.toLowerCase()
      const tabClasses = ['voice', 'speed', 'pitch', 'silence', 'emphasis']

      for (let i = tabClasses.length; i > 0; i--) {
        const elementList = document.getElementsByClassName(tabClasses[i - 1])

        if (t('_tabNames.' + tabClasses[i - 1]).toLocaleLowerCase() == selectedClass) {
          // for the tags matching the selected Tab
          for (let j = elementList.length; j > 0; j--) {
            elementList[j - 1].className += ' cream-teal' // highlight the tags in text-area
          }
        } else {
          for (let j = elementList.length; j > 0; j--) {
            elementList[j - 1].className = elementList[j - 1].className.replace('cream-teal', '') // UN-highlight the tags in text-area
          }
        }
      }
    }

    watchEffect(() => {
      props.selectedTitle
      changeTagsClass(props.selectedTitle)
    })

    onMounted(() => {
      document.addEventListener('click', () => {
        // document.addEventListener("paste", handlePaste);
        if (document.activeElement != textArea.value && textArea.value !== null) {
          if (textArea.value.innerText.length > 1) {
            placeholderText.value = false
            // silenceApply.disabled = true;
          } else {
            placeholderText.value = true
          }
        } else {
          placeholderText.value = false
        }
      })
    })

    return {
      textareaFocus,
      textArea,
      regex,
      placeholderText,
      handlePaste
    }
  }
})
</script>
<style lang="sass">
.container
  height: 164px
  word-break: break-all
  padding: 16px
  border: 1px solid #EDEDE8
  border-radius: 4px
  overflow: auto

.cream-teal
  &:before
    background: $colorCreamteal !important
  &:after
    background: $colorCreamteal !important
.speed
  font-size: 16px
  &:before
    @include effectsTagsStyle(speedChange)
  &:after
    @include effectsTagsStyle(speedDefault)

.pitch
  font-size: 16px
  &:before
    @include effectsTagsStyle(pitchChange)
  &:after
    @include effectsTagsStyle(pitchDefault)

.silence
  font-size: 16px
  &:before
    @include effectsTagsStyle(silenceChange)

.emphasis
  font-size: 16px
  &:before
    @include effectsTagsStyle(emphasisChange)
  &:after
    @include effectsTagsStyle(emphasisDefault)
// .tarea:empty:before
//   content: attr(data-placeholder)
//   color: #08082B
//   font-family: 'Faro Variable'
//   font-style: normal
//   font-weight: 376
//   font-size: 16px
//   line-height: 150%

.tarea
  width: 100%
  height: 100%
  border: none
  outline: none
  padding-left: 8px
  cursor: text
  font-size: 16px
  font-weight: 376
  color: #08082B
  line-height: inherit

.placeholder
  // display: block
  color: #08082B
  font-family: 'Faro Variable'
  font-style: normal
  font-weight: 376
  font-size: 16px
  line-height: 150%

.lang--voice
  display: unset
  .lang,
  .voice
    background: #EDEDE8
    font-style: normal
    font-weight: 376
    font-size: 14px
    line-height: 150%
    text-align: center
    color: #08082B
    border-radius: 4px
    padding: 4px 8px
    display: inline-flex
    margin-right: 8px
    @media screen and (max-width: $mdBreakpoint)
      margin-bottom: 8px

  .cream-teal
    background: $colorCreamteal
</style>
