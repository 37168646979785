
import { defineComponent, inject, provide } from "vue";
export default defineComponent({
  props: ["title"],
  setup() {
    const selectedTitle = inject("selectedTitle");
    provide("selectedTitle", selectedTitle);
    return {
      selectedTitle,
    };
  },
});
