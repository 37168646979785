const removeInvalidTags = (tarea) => {
  const modifyChildNodeCallback = (childNode) => {
    let isValidTab = false

    const validTabs = ['speed', 'pitch', 'silence', 'emphasis']
    for (let i = validTabs.length; i > 0; i--) {
      if (childNode.className) {
        if (childNode.className.indexOf(validTabs[i - 1]) != -1) {
          isValidTab = true
          break
        }
      }
    }

    if (childNode.nodeName != 'SPAN' && childNode.nodeName != '#text') {
      childNode.insertAdjacentHTML("afterend", childNode.innerHTML)
      childNode.remove()
    } else if (childNode.nodeName == 'SPAN' && childNode.getAttribute('data-val') == null) {
      childNode.insertAdjacentHTML("afterend", childNode.innerHTML)
      childNode.remove()
    } else if (childNode.nodeName == 'SPAN' && !isValidTab) {
      childNode.insertAdjacentHTML("afterend", childNode.innerHTML)
      childNode.remove()
    }
    isValidTab = false
  }

  const modifyChildNodes = (element, callback) => {
    // Base case: If the element has no child nodes, return
    if (element.childNodes.length === 0) {
      return;
    }

    // Loop through each child node
    for (let i = 0; i < element.childNodes.length; i++) {
      const childNode = element.childNodes[i];

      //Skip nodes that are already modified
      if (childNode.modified) {
        continue;
      }

      // Apply the callback function to modify the child node
      modifyChildNodeCallback(childNode);

      childNode.modified = true

      // Recursively call the function on each child node
      if (childNode.nodeType === Node.ELEMENT_NODE) {
        modifyChildNodes(childNode, callback);
      }
    }

    for (let i = 0; i < element.childNodes.length; i++) {
      const childNode = element.childNodes[i];
      delete childNode.modified;
    }
  }

  modifyChildNodes(tarea, modifyChildNodeCallback);
  tarea.normalize()

}

export { removeInvalidTags }