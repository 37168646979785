
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    toolTipType: {
      type: String,
      default: 'customtoottip'
    },
    toolTipText: {
      type: String,
      default: 'This is a tooltip'
    },
    disableTooltip: {
      type: Boolean
    }
  }
})
