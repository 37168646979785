
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String
    }
  }
})
