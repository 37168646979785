const ssmlToHtml = (userResponseSsml, translate) => {
  let it = userResponseSsml
  // pre-handle break void elements
  // TEST START
  const breakTimeValues = [
    "0ms",
    "100ms",
    "250ms",
    "500ms",
    "1s",
    "2s",
    "3s",
    "4s",
    "5s",
  ];

  if (it.indexOf("<break time=") != -1) {
    for (let i = breakTimeValues.length; i > 0; i--) {
      it = it.replaceAll(
        '<break time="' + breakTimeValues[i - 1] + '" />',
        '<break time="' + breakTimeValues[i - 1] + '"></break>'
      );
    }
  }
  // TEST END
  const parser = new DOMParser();
  const ssml = parser.parseFromString(it, "text/html")

  const parent = ssml.body.getElementsByTagName('speak');

  if (parent.length && ssml.body.getElementsByTagName('prosody')[0] != undefined) {
    parent[0].after(ssml.body.getElementsByTagName('prosody')[0], parent[0])
    parent[0].remove()
  } else if (ssml.body.getElementsByTagName('speak')[0] == undefined) {
    // console.log();
  }
  else {
    parent[0].insertAdjacentHTML('afterend', ssml.body.getElementsByTagName('speak')[0].innerHTML)
    parent[0].remove()
  }

  const prosodyTags = ssml.body.getElementsByTagName('prosody')
  const apiMid = 1;
  const apiMin = 0.25;
  const apiMax = 4.0;
  const uiMid = 1;
  const uiMin = 0.8;
  const uiMax = 1.2;
  function speedApiValue(num) {

    const dataVal = num > uiMid
      ? (((apiMax - apiMid) / (uiMax - uiMid)) * (num - uiMid) + apiMid).toFixed(2)
      : num < uiMid
        ? (((apiMid - apiMin) / (uiMid - uiMin)) * (num - uiMin) + apiMin).toFixed(2)
        : apiMid
    return dataVal
  }
  function speedUiValue(num) {
    const speedchange = num > uiMid
      ? (((uiMax - uiMin) / (apiMax - apiMid)) * (num - apiMid) + uiMid).toFixed(2)
      : num < uiMid
        ? (((uiMid - uiMin) / (apiMid - apiMin)) * (num - apiMin) + uiMin).toFixed(2)
        : apiMid
    return speedchange
  }

  function pitchUiValue(num) {
    const uiMin = 0.8;
    const uiMax = 1.2;
    const apiMax = 20;
    const apiMin = -20;
    const dataVal = (
      (((num - apiMin) * (uiMax - uiMin)) / (apiMax - apiMin) + uiMin).toFixed(2)
    )
    return dataVal
  }
  function pitchApiValue(num) {
    const uiMin = 0.3;
    const uiMax = 0.6;
    const apiMax = 20;
    const apiMin = -20;
    const dataVal = (
      (((num - apiMin) * (uiMax - uiMin)) / (apiMax - apiMin) + uiMin).toFixed(2)
    )
    return dataVal
  }

  for (let i = prosodyTags.length; i > 0; i--) {
    const prosodyTag = prosodyTags[i - 1];
    const isSpeedTag = prosodyTag.attributes.getNamedItem('rate') !== null;
    const isPitchTag = prosodyTag.attributes.getNamedItem('pitch') !== null;

    const spanSpeed = document.createElement("span");
    if (isSpeedTag) {
      let speedchange: string | number | void = prosodyTag.attributes.getNamedItem('rate').value

      if (speedchange.includes('%')) {
        speedchange = parseInt(prosodyTag.attributes.getNamedItem('rate').value) / 100
      } else {
        speedchange = speedUiValue(speedchange)
      }
      const speeddefault = "1"
      const dataVal = speedApiValue(speedchange).toString()

      spanSpeed.className = 'speed'
      spanSpeed.setAttribute('speedchange', translate('_tabNames.speed') + ": " + speedchange)
      spanSpeed.setAttribute('speeddefault', translate('_tabNames.speed') + ": " + speeddefault)
      spanSpeed.setAttribute('data-val', dataVal)

      if (!isPitchTag) {
        spanSpeed.innerHTML = prosodyTag.innerHTML
      }

      prosodyTag.after(spanSpeed, prosodyTag)
    }

    if (isPitchTag) {
      const pitchdefault = "1"
      let dataVal: string | number = prosodyTag.attributes.getNamedItem('pitch').value

      if (dataVal.includes('%')) {
        dataVal = parseInt(prosodyTag.attributes.getNamedItem('pitch').value.replace('%', ''))
        dataVal = pitchApiValue(dataVal)
      }
      let pitchchange = pitchUiValue(dataVal).toString()
      if (pitchchange === '0.80' || pitchchange == '1.20') {
        pitchchange = pitchchange.substring(0, 3)
      }

      const spanPitch = document.createElement("span");
      spanPitch.className = 'pitch'
      spanPitch.setAttribute('pitchchange', translate('_tabNames.pitch') + ": " + pitchchange)
      spanPitch.setAttribute('pitchdefault', translate('_tabNames.pitch') + ": " + pitchdefault)
      spanPitch.setAttribute('data-val', dataVal.toString())
      spanPitch.innerHTML = prosodyTag.innerHTML

      if (isSpeedTag) {
        spanSpeed.insertAdjacentElement("afterbegin", spanPitch)
      } else {
        prosodyTag.after(spanPitch, prosodyTag)
      }
    }

    prosodyTag.remove()
  }

  const silenceTags = ssml.body.getElementsByTagName('break')

  for (let i = silenceTags.length; i > 0; i--) {
    const silenceTag = silenceTags[i - 1]
    const silenceValue = silenceTags[i - 1].attributes.getNamedItem('time').value
    const span = document.createElement("span")
    span.className = "silence"
    span.setAttribute('silencechange', translate('_tabNames.silence') + ": " + silenceValue)
    span.setAttribute('data-val', silenceValue)

    // span.innerHTML = silenceTag.innerHTML
    silenceTag.after(span, silenceTag)
    span.after(silenceTag, silenceTag.innerHTML)
    silenceTag.remove()
  }

  const emphasisTags = ssml.body.getElementsByTagName('emphasis')

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  for (let i = emphasisTags.length; i > 0; i--) {
    const emphasisTag = emphasisTags[i - 1]
    const emphasisValue = capitalizeFirstLetter(emphasisTag.attributes.getNamedItem('level').value)

    const span = document.createElement("span")
    span.className = "emphasis"
    span.setAttribute('emphasischange', translate('_tabNames.emphasis') + "Start: " + emphasisValue)
    span.setAttribute('emphasisdefault', translate('_tabNames.emphasis') + "End: " + emphasisValue)
    span.setAttribute('data-val', emphasisValue)
    span.innerHTML = emphasisTag.innerHTML
    emphasisTag.after(span, emphasisTag)
    emphasisTag.remove()
  }

  return ssml.body
}

export { ssmlToHtml }