const addTagsToSelection = (selection, tagsValue, tagsDefaultValue, tagsApiValue, tagClass, translate, action) => {
  let selrange = null
  let disableApply = true
  const tarea = document.getElementsByClassName('tarea')[0]

  if (selection.rangeCount > 0) {
    selrange = selection.getRangeAt(0)
    let isValidTab = false

    const validTabs = ['tarea', 'speed', 'pitch', 'silence', 'emphasis']
    for (let i = validTabs.length; i > 0; i--) {
      if (selection.anchorNode.parentElement.className.indexOf(validTabs[i - 1]) != -1) {
        isValidTab = true
        break
      }
    }
    // if (isValidTab && selrange.collapsed == false && tagsValue != 1 && tagsValue != "0ms") {
    if (isValidTab) {
      if (selrange.collapsed == false) {
        const parent = selrange.commonAncestorContainer.parentElement
        let isCreateTag = false
        let isUpdateTag = false
        let isRemoveTag = false

        // parent tag is same as current tab
        if (parent.className.includes(tagClass)) {
          // isCreateTag = false;
          if (tagClass == 'emphasis') {
            tagsApiValue = tagsApiValue.toLowerCase()
          }

          if (parent.attributes.getNamedItem('data-val').value != tagsApiValue) {
            // parent tag's data-val != currently selected value
            // const ancestor = selrange.commonAncestorContainer.parentElement.parentElement

            if (parent.innerText.trim() == selection.toString().trim()) {
              const ancestor = selrange.commonAncestorContainer.parentElement.parentElement
              const isValidAncestor = ancestor.className.includes(tagClass)
              // if(ancestor.className.includes(tagClass) && ancestor.attributes.getNamedItem('data-val').value == tagsApiValue){
              if (isValidAncestor && ancestor.attributes.getNamedItem('data-val').value == tagsApiValue) {
                isRemoveTag = true
                disableApply = false
                // parent.after(selection.toString(), parent)
                // parent.remove();
              } else if (!isValidAncestor && tagsValue == tagsDefaultValue) {
                isRemoveTag = true
                disableApply = false
              } else {
                isUpdateTag = true // update parent tag's values
              }
            } else {
              isCreateTag = true
              disableApply = false
            }
          }
        } else if (tagsValue != tagsDefaultValue) {
          isCreateTag = true
        }

        // if (action == 'CRUD') {}
        if (action == 'CRUD') {
          if (isCreateTag) {
            const span = document.createElement('span')
            span.className = tagClass + ' cream-teal'
            // span.setAttribute("id","speed_"+Math.floor(Date.now() / 1000));
            'dataLayer' in window
              ? window.dataLayer.push({
                event: `vog_select_${tagClass}`,
                eventCategory: 'voice_over_generator',
                eventAction: 'apply',
                eventLabel: `${tagClass}_${tagClass == 'emphasis' ? tagsApiValue : tagsValue}`
              })
              : null
            if (tagClass == 'emphasis') {
              span.setAttribute(
                tagClass + 'Change',
                translate('_tabNames.' + tagClass) + ' start: ' + tagsValue.toString()
              )
              span.setAttribute(
                tagClass + 'Default',
                translate('_tabNames.' + tagClass) + ' end: ' + tagsValue.toString()
              )
              span.setAttribute('data-val', tagsApiValue.toLowerCase())
            } else if (tagClass == 'silence') {
              span.setAttribute(tagClass + 'Change', translate('_tabNames.' + tagClass) + ': ' + tagsValue.toString())
              span.setAttribute(
                tagClass + 'Default',
                translate('_tabNames.' + tagClass) + ': ' + tagsDefaultValue.toString()
              )
              span.setAttribute('data-val', tagsApiValue)
            } else {
              span.setAttribute(tagClass + 'Change', translate('_tabNames.' + tagClass) + ': ' + tagsValue.toString())
              span.setAttribute(
                tagClass + 'Default',
                translate('_tabNames.' + tagClass) + ': ' + tagsDefaultValue.toString()
              )
              span.setAttribute('data-val', tagsApiValue)
            }
            if (tagClass == 'silence') {
              selrange.insertNode(span)
              selrange.selectNodeContents(span)
              selrange.collapse(false)
              selection.removeAllRanges()
              selection.addRange(selrange)
              selection.removeAllRanges()
              // parent.parentElement.innerHTML += ''
              tarea.normalize()
            } else {
              selrange.surroundContents(span)
            }
            // span.innerHTML = ''+span.innerHTML;
            span.insertAdjacentHTML('afterbegin', ' ')
            if (parent.className == 'tarea') {
              parent.innerHTML = parent.innerHTML.trim()
              parent.innerHTML = parent.innerHTML.replace(/&nbsp;/g, '')
              parent.insertAdjacentHTML('afterbegin', '&nbsp;')
              parent.insertAdjacentHTML('beforeend', '&nbsp;')
            }
          } else if (isUpdateTag) {
            parent.setAttribute(tagClass + 'Change', translate('_tabNames.' + tagClass) + ': ' + tagsValue.toString())
            parent.setAttribute('data-val', tagsApiValue)
            'dataLayer' in window
              ? window.dataLayer.push({
                event: `vog_select_${tagClass}`,
                eventCategory: 'voice_over_generator',
                eventAction: 'apply',
                eventLabel: `${tagClass}_${tagsValue}`
              })
              : null
            if (tagClass == 'emphasis') {
              parent.setAttribute(
                tagClass + 'Default',
                translate('_tabNames.' + tagClass) + ' end: ' + tagsValue.toString()
              )
            }
            // update parent tag's values
          } else if (isRemoveTag) {
            parent.insertAdjacentText('afterEnd', parent.innerText.trim())
            // const ancestor = selrange.commonAncestorContainer.parentElement.parentElement
            selection.removeRange(selrange)
            parent.remove()
            'dataLayer' in window
              ? window.dataLayer.push({
                event: `vog_select_${tagClass}`,
                eventCategory: 'voice_over_generator',
                eventAction: 'apply',
                eventLabel: `${tagClass}_${tagsValue}`
              })
              : null
            // ancestor.innerHTML += '';
            tarea.normalize()
          }
        }
      } else {
        if (action == 'CRUD') {
          if (tagClass == 'silence' && selection.type == 'Caret') {
            // if(selection.anchorNode != undefined){
            //   selection.collapseToEnd()
            // }
            // const parent = selrange.commonAncestorContainer
            'dataLayer' in window
              ? window.dataLayer.push({
                event: `vog_select_${tagClass}`,
                eventCategory: 'voice_over_generator',
                eventAction: 'apply',
                eventLabel: `${tagClass}_${tagsApiValue}`
              })
              : null
            const span = document.createElement('span')
            span.className = tagClass + ' cream-teal'
            span.setAttribute(tagClass + 'Change', translate('_tabNames.' + tagClass) + ': ' + tagsValue.toString())
            span.setAttribute(
              tagClass + 'Default',
              translate('_tabNames.' + tagClass) + ': ' + tagsDefaultValue.toString()
            )
            span.setAttribute('data-val', tagsApiValue)
            span.innerHTML = ' ' + span.innerHTML
            selrange.insertNode(span)
            selection.removeAllRanges()
            // span.insertAdjacentHTML('afterend', ' ')
            tarea.normalize()
          }
        }
      }
    }
  }

  return disableApply
}

export { addTagsToSelection }
