<template>
  <div :class="toolTipType + 'tooltip-container'" v-if="disableTooltip">
    {{ toolTipText }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    toolTipType: {
      type: String,
      default: 'customtoottip'
    },
    toolTipText: {
      type: String,
      default: 'This is a tooltip'
    },
    disableTooltip: {
      type: Boolean
    }
  }
})
</script>
<style lang="sass">
.downloadbtntooltip-container
  @include customToolTip(unset,calc( 100% + 10px ),63px,$colorDarkBlue,$colorWhite,12.8rem)
  @media screen and (max-width: $xsBreakpoint)
    width: 80%
    left: 155px

.btn1tooltip-container
  @include customToolTip(100%,unset,27%,$colorWhite,$colorBlack,fit-content)
  @media screen and (max-width: $xsBreakpoint)
    left: 155px

.btn2tooltip-container
  @include customToolTip(100%,unset,47%,$colorWhite,$colorBlack,fit-content)
  @media screen and (max-width: $xsBreakpoint)
    left: 155px

.btn3tooltip-container
  @include customToolTip(100%,unset,67%,$colorWhite,$colorBlack,fit-content)
  @media screen and (max-width: $xsBreakpoint)
    left: 155px
.btn4tooltip-container
  @include customToolTip(100%,unset,87%,$colorWhite,$colorBlack,fit-content)
  @media screen and (max-width: $xsBreakpoint)
    left: 155px
.applybtntooltip-container
  @include customToolTip(unset,calc( 100% + 10px ),0,$colorDarkBlue,$colorWhite, 12.8rem)
  @media screen and (max-width: $xsBreakpoint)
    width: 100%

.hidden
    opacity: 0
    visibility: hidden
.visible
    opacity: 1
    visibility: visible
</style>
