import { ref } from 'vue'

const disableAction = () => {
  let isValidTab = false
  let disableApplyPlay = true
  const selection = ref<Selection>(document.getSelection())
  let selrange = null
  isValidTab = false

  if (selection.value.rangeCount > 0) {
    selrange = selection.value.getRangeAt(0)
    const validTabs = ['tarea', 'container', 'speed', 'pitch', 'silence', 'emphasis']
    for (let i = validTabs.length; i > 0; i--) {
      if (selection.value.anchorNode.parentElement.className.indexOf(validTabs[i - 1]) != -1) {
        isValidTab = true
        break
      }
    }

    if (isValidTab && selrange.collapsed == false) {
      if (selection.value.toString().length > 0) {
        disableApplyPlay = false
      }
    } else if (selrange.collapsed == true) {
      disableApplyPlay = true
    }
  } else {
    disableApplyPlay = true
  }

  return disableApplyPlay
}

export { disableAction }
