<template>
  <div class="radio-label">
    <label class="radio-lbl" @click="$emit('onClick', $event)" :class="{ disabled: disabled }"
      >{{ label }}
      <input
        type="radio"
        :checked="checked"
        :disabled="disabled"
        :name="name"
        :value="values"
        :class="className"
        @change="$emit('onChange', $event)"
      />
      <span class="checkmark" :class="{ disabled: disabled }"></span>
    </label>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    optionId: {
      type: Number,
      default: 0
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    packMembers: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'label'
    },
    className: {
      type: String,
      default: 'radio'
    },
    values: {
      type: String
    },
    name: {
      type: String
    }
  }
})
</script>
<style lang="sass" scoped>

.radio-label
  display: flex
  align-items: center
.radio-lbl
  display: block
  position: relative
  padding-left: 2.2rem
  font-family: 'Faro-Variable'
  font-style: normal
  font-weight: 376
  font-size: 1.1rem
  color: $colorDarkBlue
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  @media screen and (max-width: $xsBreakpoint)
    padding-left: 1.7rem
  &.disabled
    color: $colorCoolGray
  input
    position: absolute
    opacity: 0
    cursor: pointer
    &:checked ~ .checkmark
      background-color: $colorWhite
      border: 1px solid $colorCoolGray
    &:checked ~ .checkmark:after
      display: block

  .checkmark
    position: absolute
    transform: translateY(-50%)
    top: 50%
    left: 0
    height: 1.1rem
    width: 1.1rem
    background-color: $colorWhite
    border-radius: 50%
    border: 1px solid $colorCoolGray
    &:after
      content: ""
      position: absolute
      display: none
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 0.7rem
      height: 0.7rem
      border-radius: 50%
      background: $colorDarkBlue
  .disabled
    &:after
      background-color: $colorFogGray

  &:hover input ~ .checkmark
    background-color: $colorLightGray
</style>
