const convertToSsml = (outerHtml) => {
  const parser = new DOMParser();
  const html = outerHtml.replaceAll('&nbsp;', '').replace(/[<]br[^>]*[>]/gi, "")

  const ssml = parser.parseFromString(html, 'text/html');

  const searchTags = [['speed', 'prosody', 'rate'],
  ['pitch', 'prosody', 'pitch'],
  ['emphasis', 'emphasis', 'level']
  ];

  function PrepSSMLTag(el, ssml, tag, attr) {
    const val = el.getAttribute('data-val');
    const txt = el.innerHTML;

    const elementTemp = ssml.createElement(tag);
    elementTemp.innerHTML = txt
    elementTemp.setAttribute(attr, val);

    return elementTemp;
  }

  function PrepSSMLSilenceTag(el, ssml) {
    const val = el.getAttribute('data-val');
    const txt = el.innerHTML;
    const elementTmp = ssml.createElement('break');
    elementTmp.setAttribute('time', val);
    return [elementTmp, txt];
  }

  function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  for (let i = searchTags.length; i > 0; i--) {
    const searchClass = searchTags[i - 1][0];
    const newTag = searchTags[i - 1][1];
    const newAttr = searchTags[i - 1][2];
    const elementList = ssml.getElementsByClassName(searchClass);

    for (let j = elementList.length; j > 0; j--) {
      const elementTmp = PrepSSMLTag(elementList[j - 1], ssml, newTag, newAttr);
      insertAfter(elementTmp, elementList[j - 1]);
      elementList[j - 1].remove();
    }
  }

  const elementList = ssml.getElementsByClassName('silence');
  for (let j = elementList.length; j > 0; j--) {
    const elementTmpArr = PrepSSMLSilenceTag(elementList[j - 1], ssml);

    elementList[j - 1].parentNode.insertBefore(elementTmpArr[0], elementList[j - 1])
    elementList[j - 1].insertAdjacentHTML('beforebegin', elementTmpArr[1]);
    elementList[j - 1].remove();
  }

  // return '<speak><prosody rate="'+speedValue+'" pitch="'+pitchValue+'st">'+ssml.getElementsByClassName('tarea')[0].innerHTML+'</prosody></speak>';
  return '<speak>' + ssml.getElementsByClassName('tarea')[0].innerHTML.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, '') + '</speak>'

}

export { convertToSsml }