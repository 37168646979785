
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    optionId: {
      type: Number,
      default: 0
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    packMembers: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'label'
    },
    className: {
      type: String,
      default: 'radio'
    },
    values: {
      type: String
    },
    name: {
      type: String
    }
  }
})
