
import { defineComponent, ref, computed, watchEffect } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { VoicesMutationTypes as VoicesMutationType } from '@/store/voices/mutations'
import PitchTabSlider from 'vue-slider-component'
import sliderDataOption from '@/mixins/sliderOptions'
import Button from './UI/Button.vue'
import { addTagsToSelection } from '@/utils/addTagsToSelection'
import CustomTooltip from '@/components/UI/CustomToolTip.vue'

export default defineComponent({
  components: {
    PitchTabSlider,
    Button,
    CustomTooltip
  },
  props: {
    pitchvalue: {
      type: String
    },
    disableApplyPlay: {
      type: Boolean
    }
  },
  setup(props) {
    const { sliderDataOptions } = sliderDataOption()
    const store = useStore()
    const { t } = useI18n()
    // const disableApplyButton = ref(true)
    const pitchValue = ref(1)
    const pitchDefault = '1'
    const uiMin = 0.8
    const uiMax = 1.2
    const apiMax = 0.6
    const apiMin = 0.3
    const selection = document.getSelection()
    const tagClass = 'pitch'
    const showTooltip = ref(false)

    const pitchStoreValue = computed(() => store.state.voices.voicePitchValue)
    const pitchApiValue = computed(() =>
      (((pitchValue.value - uiMin) * (apiMax - apiMin)) / (uiMax - uiMin) + apiMin).toFixed(2)
    )

    const disableApplyButton = computed(() => {
      let value = true
      const conditions = addTagsToSelection(
        selection,
        pitchValue.value,
        pitchDefault,
        pitchApiValue.value,
        tagClass,
        t,
        ''
      )

      if (props.disableApplyPlay == false && pitchValue.value != 1) {
        value = false
      }

      if (conditions == false) {
        value = false
      }
      return value
    })

    const applyEffects = () => {
      if (selection.isCollapsed == true) {
        store.commit(VoicesMutationType.SET_VOICE_PITCH_VALUE, pitchApiValue.value)
      }
      addTagsToSelection(selection, pitchValue.value, pitchDefault, pitchApiValue.value, tagClass, t, 'CRUD')
    }

    watchEffect(() => {
      if (pitchStoreValue.value == 0) {
        pitchValue.value = 1
      }
    })

    return {
      pitchValue,
      sliderDataOptions,
      applyEffects,
      disableApplyButton,
      showTooltip
    }
  }
})
