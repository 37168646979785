const handlePaste = (e, maxLength) => {
  const regex = /[\n\s\u200B]+/g;
  const text = (e.clipboardData || navigator.clipboard.writeText).getData("text");
  const parser = new DOMParser();
  const cvtext = parser.parseFromString(text, "text/html");
  const selection = document.getSelection();

  function sliceStringWithHtmlLimit(htmlString, maxLimit) {
    let length = 0;
    let slicedHtml = "";

    const regex = /<\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)>|(\b\w+\b)|<\/?span\b[^>]*>/g;
    const chunks = htmlString.replace('&nbsp;', " ").match(regex).filter(Boolean); //.reverse();        

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];

      if (!chunk) {
        continue;
      }

      if (chunk.startsWith("<") || chunk.startsWith("</")) {
        slicedHtml += chunk;
      } else {
        const chunkLength = chunk.length;

        if (length > maxLimit) {
          break;
        } else {
          // does the length of the current word exceed the permissible limit pending?
          slicedHtml += (chunkLength > (maxLimit - length)) ? " " + chunk.substring(0, maxLimit - length) : " " + chunk;
        }

        length += chunkLength;
      }
    }
    return {
      remainingHtml: slicedHtml,
    };
  }

  const stripText = (content, maxLen) => {
    let result = '';

    for (const char of content) {
      if (maxLen <= 0) break;
      else if (!char.match(regex)) maxLen--;
      result += char;
    }
    return result;
  }

  if (e.target.className == "tarea") {
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();

      const totalLength =
        cvtext.body.innerText.replace(regex, "").length +
        e.target.innerText.replace(regex, "").length;
      const ml = maxLength - e.target.innerText.replace(regex, '').length

      const div = document.createElement('div')
      div.className = 'tmpdiv'
      div.innerHTML = cvtext.body.innerHTML

      let textNode = null;

      const childNodes = div.childNodes;

      let allChildrenAreSpanTagsOrTextNodes = true;

      for (let i = 0; i < childNodes.length; i++) {
        const currentNode = childNodes[i];
        if (currentNode.nodeName !== 'SPAN' && currentNode.nodeType !== 3) {
          allChildrenAreSpanTagsOrTextNodes = false;
          break;
        } else if (currentNode.nodeName === 'SPAN' && !(currentNode as HTMLElement).hasAttribute('data-val')) {
          allChildrenAreSpanTagsOrTextNodes = false;
          break;
        }
      }

      if (allChildrenAreSpanTagsOrTextNodes) {
        range.insertNode(div);
      } else {
        const textNodeValue = (totalLength >= maxLength) ? stripText(cvtext.body.innerText, ml) : cvtext.body.innerText
        textNode = document.createTextNode(textNodeValue)
        range.insertNode(textNode);
      }

      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      const tmpdiv = document.getElementsByClassName('tmpdiv')[0]

      if (allChildrenAreSpanTagsOrTextNodes) {
        if (totalLength >= maxLength) {
          const result = sliceStringWithHtmlLimit(tmpdiv.innerHTML, ml);
          const remainingHtml = result.remainingHtml;
          tmpdiv.insertAdjacentHTML("afterend", remainingHtml)
        } else {
          tmpdiv.insertAdjacentHTML("afterend", tmpdiv.innerHTML)
        }
        e.target.getElementsByClassName('tmpdiv')[0].remove()
        e.target.normalize()
        selection.selectAllChildren(e.target);
        selection.collapseToEnd();
      }
    }
  }
}

export { handlePaste }