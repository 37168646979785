<template>
  <div class="speed-slider">
    <SpeedTabSlider v-model="speedValue" :min="0.8" :max="1.2" :interval="0.01" v-bind="sliderDataOptions" />
    <div class="applybtn-tooltip">
      <div class="applybutton" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
        <Button
          :text="$t('_buttons.apply')"
          buttonType="speed-tab-apply"
          :isDisabled="disableApplyButton"
          @onClick="applyEffects"
        />
      </div>
      <CustomTooltip
        :style="{ opacity: showTooltip ? 1 : 0, visibility: showTooltip ? 'visible' : 'hidden' }"
        :toolTipText="$t('_toolTip.effectDisabledToolTipText')"
        :disableTooltip="disableApplyButton"
        toolTipType="applybtn"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, watchEffect } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { VoicesMutationTypes as VoicesMutationType } from '@/store/voices/mutations'
import SpeedTabSlider from 'vue-slider-component'
import sliderDataOption from '@/mixins/sliderOptions'
import Button from './UI/Button.vue'
import { addTagsToSelection } from '@/utils/addTagsToSelection'
import CustomTooltip from '@/components/UI/CustomToolTip.vue'

export default defineComponent({
  components: {
    SpeedTabSlider,
    Button,
    CustomTooltip
  },

  props: {
    disableApplyPlay: {
      type: Boolean
    }
  },
  setup(props) {
    const { sliderDataOptions } = sliderDataOption()
    const speedValue = ref(1)
    const speedDefault = '1'
    const store = useStore()
    const { t } = useI18n()
    const apiMid = 1
    const apiMin = 0.25
    const apiMax = 4.0
    const uiMid = 1
    const uiMin = 0.8
    const uiMax = 1.2
    const selection = document.getSelection()
    const tagClass = 'speed'
    const showTooltip = ref(false)

    const speedStore = computed(() => store.state.voices.voiceSpeedValue)

    const speedApiValue = computed(() =>
      speedValue.value > uiMid
        ? (((apiMax - apiMid) / (uiMax - uiMid)) * (speedValue.value - uiMid) + apiMid).toFixed(2)
        : speedValue.value < uiMid
        ? (((apiMid - apiMin) / (uiMid - uiMin)) * (speedValue.value - uiMin) + apiMin).toFixed(2)
        : apiMid
    )

    const disableApplyButton = computed(() => {
      let value = true
      const conditions = addTagsToSelection(
        selection,
        speedValue.value,
        speedDefault,
        speedApiValue.value,
        tagClass,
        t,
        ''
      )

      if (props.disableApplyPlay == false && speedValue.value != 1) {
        value = false
      }

      if (conditions == false) {
        value = false
      }
      return value
    })

    const applyEffects = () => {
      if (selection.isCollapsed == true) {
        store.commit(VoicesMutationType.SET_VOICE_SPEED_VALUE, speedApiValue.value)
      }
      addTagsToSelection(selection, speedValue.value, speedDefault, speedApiValue.value, tagClass, t, 'CRUD')
    }

    watchEffect(() => {
      if (speedStore.value == 1) {
        speedValue.value = 1
      }
    })

    return {
      speedValue,
      speedStore,
      sliderDataOptions,
      applyEffects,
      disableApplyButton,
      showTooltip
    }
  }
})
</script>
<style lang="sass" scoped>
.speed-slider
  display: flex
  align-items: center
  gap: 16px
  padding: 24px 16px
  @media screen and (max-width: $mdBreakpoint)
    gap: 10px
  @media screen and (max-width: $xsBreakpoint)
    flex-direction: column
    gap: 16px
  .applybtn-tooltip
    position: relative

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.3s ease


.fade-enter-from,
.fade-leave-to
  opacity: 0.3 ease


// .fade-enter-active,
// .fade-leave-active
//   transition: opacity 0.3s ease
// // .fade-enter,
// .fade-leave-to
//   opacity: 0

// .fade-enter-from
//   opacity: 0

// .fade-leave-from
//   opacity: 1
</style>
