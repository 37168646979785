<template>
  <div :class="rootClass">
    <label>{{ inputLabel }}</label>
    <input
      type="text"
      :disabled="isDisabled"
      :value="projectName"
      @change="$emit('onChange', $event)"
      :class="inputClass"
      :maxlength="maxLength"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Input",
  props: {
    inputClass: {
      type: String,
      default: "",
    },
    inputLabel: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    rootClass: {
      type: String,
      default: "inputWrapper",
    },
    projectName: {
      type: String,
    },
    maxLength: {
      type: Number,
      default: 20,
    },
  },
});
</script>

<style lang="sass" scoped>
.project-pages
  display: flex
  flex-direction: column
  flex-wrap: wrap
  gap: 20px
  margin-bottom: 20px
  padding: 0 16px
  font-family: 'Faro-Variable'
  label
    font-weight: 689
    font-size: 16px
    color: #0A0A38
  .pp-input
    border: 1px solid #EDEDE8
    border-radius: 4px
    padding: 12px 16px
    font-size: 16px
    font-weight: 376
    color: #08082B
    &:focus
      outline: none
      border: 1px solid #EDEDE8
</style>
