
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: 'Select'
    },
    items: {
      type: Array as () => { value: string; label: string; selected: string }[],
      required: true
    },
    selectType: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
})
