
import { defineComponent } from "vue";
export default defineComponent({
  name: "Input",
  props: {
    inputClass: {
      type: String,
      default: "",
    },
    inputLabel: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    rootClass: {
      type: String,
      default: "inputWrapper",
    },
    projectName: {
      type: String,
    },
    maxLength: {
      type: Number,
      default: 20,
    },
  },
});
