<template>
  <div :class="modalType" v-show="openModal" ref="modal">
    <div :class="modalType + '-content'">
      <span class="close-modal" @click="$emit('closeModal')"></span>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String
    }
  }
})
</script>
<style lang="sass">
/* The Modal (background) */
.login-modal
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  z-index: 2
  /* padding-top: 100px; */
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: #0a0a3899
  /* opacity: 0.6; */
  border-radius: 4px
  color: #0A0A38
  font-family: 'Faro-Variable'
  font-style: normal
.open
  display: flex

  /* Modal Content */
.login-modal-content
  position: relative
  background-color: #fefefe
  margin: 0 auto
  padding: 24px
  border: 1px solid #888
  width: 40.40%
  z-index: 1
  @media screen and (max-width: $xsBreakpoint)
    width: 75%
  @media screen and (max-width: $mdBreakpoint)
    width: 70%
  h2
    font-family: 'FaroDisplayVariable'
    font-weight: 400
    font-size: 30px
    line-height: 115%
  p
    font-weight: 376
    font-size: 18px
    line-height: 150%
    padding-right: 20px
    @media screen and (max-width: $mdBreakpoint)
      padding-right: 0
/* The Close Button */
.close-modal
    position: absolute
    width: 35px
    height: 35px
    right: -17px
    top: -17px
    color: #aaaaaa
    float: right
    font-size: 28px
    font-weight: bold
    background: url('~@/assets/close-button.svg')

.close-modal:hover,
.close-modal:focus
    color: #000
    text-decoration: none
    cursor: pointer

.error-modal
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  z-index: 2
  /* padding-top: 100px; */
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: #0a0a3899
  /* opacity: 0.6; */
  border-radius: 4px
  color: #0A0A38
  font-family: 'Faro-Variable'
  font-style: normal
.error-modal-content
  position: relative
  background-color: #fefefe
  margin: 0 auto
  padding: 24px
  border: 1px solid #888
  width: 35.8%
  z-index: 1
  @media screen and (max-width: $lgBreakpoint)
    width: 42%
  @media screen and (max-width: $mdBreakpoint)
    width: 60%
  @media screen and (max-width: $xsBreakpoint)
    width: 80%
  h2
    font-family: 'FaroDisplayVariable'
    font-weight: 400
    font-size: 32px
    line-height: 115%
  p
    font-weight: 376
    font-size: 18px
    line-height: 150%
    padding-right: 20px
    margin-bottom: 34px
    @media screen and (max-width: $mdBreakpoint)
      padding-right: 0
.subscription-modal
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  z-index: 2
  /* padding-top: 100px; */
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: #0a0a3899
  /* opacity: 0.6; */
  border-radius: 4px
  color: #0A0A38
  font-family: 'Faro-Variable'
  font-style: normal
.subscription-modal-content
  position: relative
  background-color: #fefefe
  margin: 0 auto
  padding: 24px
  border: 1px solid #888
  width: 40.40%
  z-index: 1
  @media screen and (max-width: $xsBreakpoint)
    width: 75%
  @media screen and (max-width: $mdBreakpoint)
    width: 70%
  h2
    font-family: 'FaroDisplayVariable'
    font-weight: 400
    font-size: 30px
    line-height: 115%
  p
    font-weight: 376
    font-size: 18px
    line-height: 150%
    padding-right: 20px
    margin-bottom: 20px
    @media screen and (max-width: $mdBreakpoint)
      padding-right: 0
</style>
