
import { defineComponent, ref, computed, watchEffect } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { VoicesMutationTypes as VoicesMutationType } from '@/store/voices/mutations'
import SpeedTabSlider from 'vue-slider-component'
import sliderDataOption from '@/mixins/sliderOptions'
import Button from './UI/Button.vue'
import { addTagsToSelection } from '@/utils/addTagsToSelection'
import CustomTooltip from '@/components/UI/CustomToolTip.vue'

export default defineComponent({
  components: {
    SpeedTabSlider,
    Button,
    CustomTooltip
  },

  props: {
    disableApplyPlay: {
      type: Boolean
    }
  },
  setup(props) {
    const { sliderDataOptions } = sliderDataOption()
    const speedValue = ref(1)
    const speedDefault = '1'
    const store = useStore()
    const { t } = useI18n()
    const apiMid = 1
    const apiMin = 0.25
    const apiMax = 4.0
    const uiMid = 1
    const uiMin = 0.8
    const uiMax = 1.2
    const selection = document.getSelection()
    const tagClass = 'speed'
    const showTooltip = ref(false)

    const speedStore = computed(() => store.state.voices.voiceSpeedValue)

    const speedApiValue = computed(() =>
      speedValue.value > uiMid
        ? (((apiMax - apiMid) / (uiMax - uiMid)) * (speedValue.value - uiMid) + apiMid).toFixed(2)
        : speedValue.value < uiMid
        ? (((apiMid - apiMin) / (uiMid - uiMin)) * (speedValue.value - uiMin) + apiMin).toFixed(2)
        : apiMid
    )

    const disableApplyButton = computed(() => {
      let value = true
      const conditions = addTagsToSelection(
        selection,
        speedValue.value,
        speedDefault,
        speedApiValue.value,
        tagClass,
        t,
        ''
      )

      if (props.disableApplyPlay == false && speedValue.value != 1) {
        value = false
      }

      if (conditions == false) {
        value = false
      }
      return value
    })

    const applyEffects = () => {
      if (selection.isCollapsed == true) {
        store.commit(VoicesMutationType.SET_VOICE_SPEED_VALUE, speedApiValue.value)
      }
      addTagsToSelection(selection, speedValue.value, speedDefault, speedApiValue.value, tagClass, t, 'CRUD')
    }

    watchEffect(() => {
      if (speedStore.value == 1) {
        speedValue.value = 1
      }
    })

    return {
      speedValue,
      speedStore,
      sliderDataOptions,
      applyEffects,
      disableApplyButton,
      showTooltip
    }
  }
})
