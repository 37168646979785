<template>
  <div class="emphasistab-radiobtn">
    <div class="radio-btn">
      <RadioButton
        :label="$t('_radioButtons.reduced')"
        values="reduced"
        :checked="defaultEmphasis == $t('_radioButtons.reduced') ? true : false"
        @change="emphasisChange"
        name="emphasis"
      />
      <RadioButton
        :label="$t('_radioButtons.normal')"
        values="normal"
        :checked="defaultEmphasis == $t('_radioButtons.normal') ? true : false"
        @change="emphasisChange"
        name="emphasis"
      />
      <RadioButton
        :label="$t('_radioButtons.moderate')"
        values="moderate"
        :checked="defaultEmphasis == $t('_radioButtons.moderate') ? true : false"
        @change="emphasisChange"
        name="emphasis"
      />
      <RadioButton
        :label="$t('_radioButtons.strong')"
        values="strong"
        :checked="defaultEmphasis == $t('_radioButtons.strong') ? true : false"
        @change="emphasisChange"
        name="emphasis"
      />
    </div>
    <div class="applybtn-tooltip">
      <div class="applybutton" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
        <Button
          :text="$t('_buttons.apply')"
          buttonType="emphasis-tab-apply"
          :isDisabled="disableApplyButton"
          @click="applyEffects"
        />
      </div>
      <CustomTooltip
        :style="{
          opacity: showTooltip ? 1 : 0,
          visibility: showTooltip ? 'visible' : 'hidden'
        }"
        :toolTipText="$t('_toolTip.effectDisabledToolTipText')"
        :disableTooltip="disableApplyButton"
        toolTipType="applybtn"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, watchEffect } from 'vue'
import RadioButton from '@/components/UI/RadioButton.vue'
import Button from '@/components/UI/Button.vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { VoicesMutationTypes as VoicesMutationType } from '@/store/voices/mutations'
import { addTagsToSelection } from '@/utils/addTagsToSelection'
import router from '@/router'
import CustomTooltip from '@/components/UI/CustomToolTip.vue'

export default defineComponent({
  components: {
    RadioButton,
    Button,
    CustomTooltip
  },
  props: {
    userId: {
      type: String
    },
    disableApplyPlay: {
      type: Boolean
    }
  },
  setup(props) {
    const emphasisValue = ref('')
    const { t } = useI18n()
    const routeQuery = router.currentRoute.value.query
    const store = useStore()
    // const disableApplyButton = ref(true)
    const emphasisStore = computed(() => store.state.voices.voiceEmphasisValue)
    const defaultEmphasis = ref(t('_radioButtons.moderate'))
    const emphasisApiValue = ref('moderate')
    const selection = document.getSelection()
    const tagClass = 'emphasis'
    const emphasisChange = (e) => {
      emphasisValue.value = t('_radioButtons.' + e.target.value)
      emphasisApiValue.value = e.target.value
    }
    const showTooltip = ref(false)

    const disableApplyButton = computed(() => {
      let value = true
      const conditions = addTagsToSelection(
        selection,
        emphasisValue.value,
        t('_radioButtons.normal'),
        emphasisApiValue.value,
        tagClass,
        t,
        ''
      )

      if (props.disableApplyPlay == false && emphasisValue.value !== 'Normal') {
        value = false
      }

      if (conditions == false) {
        value = false
      }
      return value
    })

    const applyEffects = () => {
      if (selection.isCollapsed == true && emphasisValue.value != '') {
        store.commit(VoicesMutationType.SET_VOICE_EMPHASIS_VALUE, emphasisValue.value)
        defaultEmphasis.value = emphasisValue.value
      }
      addTagsToSelection(
        selection,
        emphasisValue.value,
        t('_radioButtons.normal'),
        emphasisApiValue.value,
        tagClass,
        t,
        'CRUD'
      )
    }

    watchEffect(() => {
      emphasisStore.value
      if (emphasisStore.value == '') {
        defaultEmphasis.value = t('_radioButtons.moderate')
        emphasisValue.value = t('_radioButtons.moderate')
        emphasisApiValue.value = 'moderate'
      }
    })

    return {
      emphasisChange,
      applyEffects,
      defaultEmphasis,
      emphasisValue,
      disableApplyButton,
      routeQuery,
      showTooltip
    }
  }
})
</script>
<style lang="sass" scoped>
.emphasistab-radiobtn
  display: flex
  padding: 24px 16px
  @media screen and (max-width: $mdBreakpoint)
    gap: 10px
  @media screen and (max-width: $xsBreakpoint)
    flex-direction: column
    gap: 16px
  .radio-btn
    width: 93%
    display: flex
    gap: 48px
    align-items: center
    @media screen and (max-width: $mdBreakpoint)
      gap: 30px
    @media screen and (max-width: $xsBreakpoint)
      justify-content: space-between
      gap: 0

.applybtn-tooltip
  position: relative
</style>
